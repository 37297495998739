import AuthLayout from '@/layouts/auth'
import withI18n from '@/utils/withTranslations'
import { ReactElement } from 'react'
import { FormLogin } from '@/src/components/forms'
import { CardWhenMobile } from '@/utils/renderers'

const Login = () => {
  return (
    <CardWhenMobile>
      <FormLogin />
    </CardWhenMobile>
  )
}

Login.getLayout = function getLayout(page: ReactElement) {
  return <AuthLayout>{page}</AuthLayout>
}

export const getServerSideProps = withI18n(ctx => {
  return {
    props: { translation: ctx.translation, locale: ctx.locale }
  }
})

export default Login
