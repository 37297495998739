import Help from '@/src/components/sidebar/help'
import DolfinLogo from '@/svgs/dolfin-logo'

const AuthLayout = ({ children }: React.PropsWithChildren) => (
  <div className='bg-neutral-0 sm:bg-neutral-50 flex relative min-h-screen w-screen items-center justify-center'>
    <div className={'fixed z-0 bottom-0 right-0 opacity-5 hidden sm:block'}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='833'
        height='786'
        viewBox='0 0 833 786'
        fill='none'>
        <circle
          cx='187.312'
          cy='457.956'
          r='129'
          transform='rotate(-22.3113 187.312 457.956)'
          fill='black'
        />
        <path
          d='M446.822 544.989L264.054 305.171L349.498 240.053C415.723 189.583 510.322 202.354 560.792 268.578C611.262 334.802 598.49 429.402 532.266 479.871L446.822 544.989Z'
          fill='black'
        />
        <rect
          x='584.856'
          y='224.438'
          width='74.027'
          height='258.192'
          transform='rotate(-67.3113 584.856 224.438)'
          fill='black'
        />
        <path
          d='M513.227 799.111L234.762 913.382L193.191 812.08C161.636 735.184 198.392 647.267 275.288 615.711C352.185 584.156 440.102 620.912 471.657 697.809L513.227 799.111Z'
          fill='black'
        />
        <rect
          x='533.836'
          y='509.282'
          width='74.027'
          height='258.192'
          transform='rotate(7.68869 533.836 509.282)'
          fill='black'
        />
        <circle
          cx='762.883'
          cy='556.142'
          r='130'
          transform='rotate(-22.3113 762.883 556.142)'
          fill='black'
        />
      </svg>
    </div>

    <div className='flex z-10 w-full items-center m-8 flex-col'>
      <div className='w-full flex justify-center text-primary mb-10'>
        <DolfinLogo width={120} />
      </div>

      {children}
    </div>

    <Help isInSidebar={false} />
  </div>
)

export default AuthLayout
